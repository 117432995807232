.card-search-image-display {
    height: 240px;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

.Image-Display {
    height: 480px;
}

.imagesPageDisplay {
    height: 120px;
    max-width: 120px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
    padding: 0%;
    filter: drop-shadow(0px 0px 3px #000000);
}
.imagesPageDisplaySmall {
    height: 50px;
    max-width: 50px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
    padding: 0%;
    filter: drop-shadow(0px 0px 3px #000000);
}
.subCardWallGrades {
    text-align: right;
    position: relative;
    top: -122px;
    margin-right: 10px;
    height: 0px;
    font-size: 21px;
    font-weight: 500;
    color: #fff;
    text-shadow: 2px 2px 2px #000, 0 0 2px #000;
}

.subCardWallIcons {
  text-align: center;
  position: relative;
  top: -30px;
  height: 0px;
  color: #fff;
  text-shadow: 2px 2px 2px #000, 0 0 2px #000;
}
.cardWallSoldIcon{
  height: 30px;
  width: 30px;
}
.subCardWallLogo {
    text-align: left;
    position: relative;
    top: -125px;
    margin-left: 5px;
    height: 0px;
    font-size: 21px;
    font-weight: 500;
    color: #fff;
    text-shadow: 2px 2px 2px #000, 0 0 2px #000;
}

.cover-image-display {
    height: 450px;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
  
  .imageBorderV2 {
    background-color: rgba(255, 255, 255);
    margin-left: 18%;
    margin-right: 18%;
    padding: 20px;
    border-radius: 20px;
    height: 400px;
  }
  
  
  
  .image-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    perspective: 1000px; /* Perspective for the 3D effect */
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flipped {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .flip-card-back {
    transform: rotateY(180deg);
  }
  
.reports {
    border: none;
    justify-content: center;
    text-align: center;
    padding-top: 25px;
}

.info-box {
    background-color: #fcfcfc;
    border-radius: 10px;
    margin: 10px;
    margin-top: 5px;
}
.info-header {
    text-align: center;
    color: #4d4d4d;
    font-weight: bold;
    font-size: smaller;
    background-color: white;
    border-radius: 15px;
    padding: 2%;
}
.info-value {
    font-size: 14px;
    padding: 2px;
    text-align: center;
    vertical-align: middle;
}
.info-title {
    text-align: right;
    font-weight: bold;
    font-size: 11px;
    vertical-align: middle !important;
    padding: 2px;
}
  .search {
    padding: 10px;
    font-size: 20px;
  }
  
  .card-search-table{
    padding-left: 8%;
    padding-right: 8%;
  }

  .search-db {
    padding-top: 25px;
    align-content: center;
    text-align: center;
}
.home {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    box-shadow: 0 3px 5px #b1b1b1;
  }
  .cardStack {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 10px;
    margin-top: 50px;
    border-radius: 10px;
    position: relative;
    top: -100px;
  }
.welcomeMessage {
  position: relative;
  top: -50px;
  text-align: center;
  padding: 10px;
  margin: 10px;
  background-color: white;
  border-radius: 10px;
}
.homeCardStack {
  text-align: center;
  padding: 10px;
  margin: 10px;
  background-color: #acf06c;
  border-radius: 10px;
  
}
  
  .headerContainer {
    width: auto;
    margin-left: 50px;
  }
  
  .headerContainer h1 {
    justify-content: center;
    align-items: center;
    font-size: 80px;
    height: 60px;
    font-weight: 50;
    color: #FFF;
    text-shadow: 5px 5px 5px #000, 0 0 2px #000;
  }
  
  .headerContainer p {
    padding: 0;
    font-size: 40px;
    font-weight: lighter;
    color: rgb(255, 255, 255);
  }
  
  .headerContainer button {
    color: rgb(75, 75, 75);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    background-color: #f0f0f0;
    height: 50px;
    width: 180px;
    color: rgb(14, 14, 14);
    text-decoration: none;
    font-size: 20px;
  }
  
  .headerContainer button:hover {
    background-color: #e9fce5;
    cursor: pointer;
    transition: 0.3s ease-in;
  }
  
  @media only screen and (max-width: 600px) {
    .home {
      justify-content: center;
      align-items: center;
    }
    .welcomeMessage {
      top: -100px;
    }
    .cardStack {
      margin-top: 10px;
    }
    .headerContainer {
      margin-left: 0px;
      border-radius: 10px;
      padding: 10px;
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #121619;
      color: white;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
    }
  
    .headerContainer h1 {
      font-size: 30px;
      height: 15px;
      color: white;
    }
  
    .headerContainer p {
      font-size: 30px;
      color: white;
    }
  
    .headerContainer button {
      background-color: white;
      color: #121619;
      margin-bottom: 30px;
    }
  
    .headerContainer button:hover {
      background-color: #acf06c;
      color: #121619;
    }
  }
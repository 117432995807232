.actions {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
}

.subBack {
    width: 75px;
    font-size: smaller;
    color: rgb(69, 69, 69);
    margin-left: 5%;
    cursor: pointer;
}
.subEdit {
    width: 75px;
    text-align: right;
    font-size: smaller;
    color: rgb(69, 69, 69);
    margin-right: 5%;
}
.sub {
    width: 100%;
    text-align: center;
}
.subAdd{
    padding-top: 25px;
}
.subAddBox {
    background-color: #fcfcfc;
    border-radius: 10px;
    margin: 10px;
}
.subAddHeader {
    text-align: center;
    color: #4d4d4d;
    font-weight: bold;
    font-size: smaller;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    background-color: white;
    border-radius: 15px;
    padding: 2%;
    height: 40px;
}
.subAddButton{
    width: 100%;
    text-align: right;
    font-size: smaller;
    color: rgb(207, 207, 207);
    padding: 10px;
}
.subInfoBox {
    border-radius: 20px;
}
.subWallHeader {
    text-align: center;
    color: #4d4d4d;
    font-weight: bold;
    font-size: smaller;
    background-color: #fcfcfc;
    border-radius: 15px;
    padding: 2%;
    height: 40px;
}
.subCardWall {
    padding: 20px;
    background-color: white;
}
.subGraderDates {
    text-align: left;
    font-size: 13px;
    display: 'flex';
    padding: 3px;
}
.subBox {
    display: flex;
    text-align: center;
    padding-bottom: 20px;
    position: relative;
    top: -180px;
}
.subHeader{
    text-align: center;
    font-size: 30px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #fcfcfc;
    text-shadow: 2px 2px 2px #000;
}
.subPositionAdjust{
    display: flex;
    position: relative;
    top: -180px;
}
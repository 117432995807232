.dashboard{
    display: flex;
}

.dashNav{
    position: fixed; /* Added this */
    top: 70px;
    left: 0;
    height: 100%;
    z-index: 1000;

    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50px;
    background-color: #040404;
    color: white !important;
    float:left;
}
.dashNav a:link{
    color: white !important;
    border-radius: 10px;
}
.dashNav a {
    color: #f3f3f3;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 10px;
}
.dashNav a:hover {
    background-color: #ffffff;
    border-radius: 10px;
    padding-left: 6px;
    padding-right: 6px;
    color: #000000 !important;
}
.dashNavLink {
    color: #ddd;
    padding-bottom: 8px;
    padding-top: 8px;
}
.dashNavLink:hover {
    color:#acf06c;
}
.dashNavLinks{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    align-items: center; 
}
.dashNavIcon {
    cursor: pointer;
    align-items: center !important;
}
.dashNavIcon:hover {
    color: #acf06c;
}
.offcanvas, .offcanvas-lg, .offcanvas-md, .offcanvas-sm, .offcanvas-xl, .offcanvas-xxl {
    --bs-offcanvas-width: 250px;
    background-color: #181818;
    color: white;
}

.btn-close {
    --bs-btn-close-color: white;
}
.dashContent {
    margin-left: 50px; /* Adjusted this value. It's 50px (width of .dashNav) + 10px (for spacing). */
    width: calc(100% - 50px); /* Adjust the width to accommodate the sidebar */
}
.register {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
}
.register-logo img{
    width: 150px;
    opacity: 0.75;
}
.register-box {
    background-color: #fcfcfc;
    border-radius: 10px;
    width: 400px;
}
h1 {
    font-size: 20px;
    opacity: 0.75;
}
h2 {
    text-align: center;
    color: #4d4d4d;
    font-weight: bold;
    font-size: smaller;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    background-color: white;
    border-radius: 15px;
    padding: 2%;
}
form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #fcfcfc;
    border-radius: 10px;
    gap: 15px
}
form input {
    padding: 10px;
    border: none;
    border-bottom: 1px solid gray;
    background-color: #fcfcfc;
    color: rgb(0, 0, 0);
}

form input:focus{
    outline: none;
}

form button {
    border: none;
    background-color: #acf06c;
    cursor: pointer;
    border-radius: 2px;
}
form span {
    font-size: 10px;
    text-align: center;
}
form p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: red;
}
form h2 {
    text-align: center;
    background-color: white;
    border-radius: 15px;
    padding: 2%;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}
.App {
  width: 100%;
  height: auto;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

body {
  padding: 0;
  margin: 0;
}

@media (max-width: 600px) {
  body {
    font-size: 60% !important;
  }
  .info-title {
    font-size: 7px !important;
    height: 24px;
    display: flex !important;
    align-items: center !important;
    justify-content: right !important;
  }
  .info-value {
    font-size: 9px !important;
  }
  .reports {
    padding-top: 0px !important;
  }
  .css-1kvnwgs-MuiDataGrid-root {
    font-size: 9px !important;
  }
  .css-5wly58-MuiDataGrid-root {
    font-size: 9px !important;
  }
  h1 {
    font-size: 14px !important;
  }
  .cover-image-display {
    height: 340px !important;
  }
  .cardImageBox {
    height: 170px !important;
  }
  .card-set {
    font-size: 12px !important;
  }
  .card-title {
    font-size: 18px !important;
  }
  .custom-info-title {
    font-size: 9px !important;
  }
  .custom-info-val {
    font-size: 10px !important;
  }
  .custom-info-misc {
    font-size: 10px !important;
  }
  .css-tzssek-MuiSvgIcon-root {
    width: .8em !important;
    height: .8em !important;
  }
  .DashCardWallNewestPadding {
    padding-left: 10px;
    padding-right: 10px;
  }
  .imagesPageDisplay {
    height: 80px !important;
    max-width: 80px !important;
  }
  .subCardWallGrades {
    top: -83px !important;
    font-size: 16px !important;
    margin-right: 7px !important;
  }
}



.searchDBdiv {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;    /* Centers vertically */
    height: 100%;           /* Adjust height as needed */
}
.searchBarContainer {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;    /* Centers vertically */
    margin: 20px;         /* Optional: Adds spacing around the search bar */
    height: 80px;           /* Adjust height as needed */
}
.searchDBbar {
    max-width: 400px;
    text-align: center;
    padding: 10px;          /* Adds padding for better UI */
    font-size: 16px;        /* Adjust font size */
    border: 1px solid #ccc; /* Optional: Border for the input */
    border-radius: 5px;     /* Optional: Rounded corners */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Shadow for effect */
}
.searchFilterContainer {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;    /* Centers vertically */
    margin: 5px;
    width: 100%;
}

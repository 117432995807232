.homeV2 {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
.blackHome{
  background-color: #000000;
}
.cardStack2 {
    width: 75%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 10px;
    margin-top: 75px;
    border-radius: 10px;
    position: relative;
    top: -100px;
}
.cardStack2Row {
  position: relative;
  font-size: small;
  text-align: center;
  padding: 10px;
  margin: 10px;
}
  .homeAlienHead {
    color: #acf06c;
    width: 375px;
    height: 375px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 10px;
    position: relative;
    top: 55px;
  }
.welcomeMessageV2 {
  position: relative;
  color: #FFF;
  text-shadow: 0px 2px 4px rgb(0, 0, 0); /* Black text shadow */
  font-size: small;
  top: -60px;
  text-align: center;
  padding: 10px;
  margin: 10px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}
.homeCardStack {
  text-align: center;
  padding: 10px;
  margin: 10px;
  background-color: #acf06c;
  border-radius: 10px;
  
}
  
  .homeAlienContainer {
    width: 100%;
  }

  
  .homeAlienContainer button {
    color: rgb(75, 75, 75);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    background-color: #f0f0f0;
    height: 50px;
    width: 180px;
    color: rgb(14, 14, 14);
    text-decoration: none;
    font-size: 20px;
  }
  
  .homeAlienContainer button:hover {
    background-color: #f13a32;
    color: #f0f0f0;
    text-shadow: 0px 0px 4px rgb(0, 0, 0);
    cursor: pointer;
    transition: 0.3s ease-in;
    box-shadow: 0 3px 10px #000000;
  }
  
  @media only screen and (max-width: 600px) {
    .home {
      justify-content: center;
      align-items: center;
    }

    .cardStack2 {
      width: 90%;
      height: 300px;
    }
  
    .headerContainer button {
      background-color: white;
      color: #121619;
      margin-bottom: 30px;
    }
  
    .headerContainer button:hover {
      background-color: #acf06c;
      color: #121619;
    }
  }
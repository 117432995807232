.cardsDashboard {
    font-size: 10px;
}

.search-db {
    padding-top: 25px;
    align-content: center;
    text-align: center;
}

.search-db input {
    font-size: small;
}
.cardV2TopBG {
    background-color: #222;
    height: 450px;
    display: flex;
    align-items: left;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 30px;
    padding-right: 30px;
}
.cardV2ImageBox {
    display: flex;
    text-align: center;
    padding-bottom: 20px;
    position: relative;
    top: -240px;
    height: 280px;
}
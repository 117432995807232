.sold {
    border: none;
    justify-content: center;
    text-align: center;
    padding-top: 25px;
}
.sold-table {
    justify-content: center;
    text-align: left;
}

.sold-info-box {
    background-color: #fcfcfc;
    border-radius: 10px;
    margin: 10px;
}
.sold-info-header {
    text-align: center;
    color: #4d4d4d;
    font-weight: bold;
    font-size: smaller;
    background-color: white;
    border-radius: 15px;
    padding: 2%;
}
.sold-info-val {
    font-size: 15px;
    padding: 2px;
    text-align: left;
}
.sold-info-title {
    text-align: right;
    font-weight: bold;
    font-size: 13px;
    vertical-align: middle !important;
    padding: 2px;
    padding-top: 5px;
}
.MuiDataGrid-root .MuiDataGrid-cell {
    white-space: normal !important;
    word-wrap: break-word !important;
  }
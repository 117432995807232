a {
    color: #4d4d4d;
}

a:hover {
    color: black;
}


.custom-card-box {
    background-color: #fcfcfc;
    border-radius: 10px;
    margin: 10px;
    width: 290px;
    font-size: 14px;
}

.card-images {
    display: flex;
    text-align: center;
    padding-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
}
.card-row-center {
    width: 100% !important;
    text-align: center;
}
.card-info {
    display: flex;
    align-content: center;
    font-size: 12px;
    justify-content: space-between;
    margin: 3px;
    background-color: #fdfdfd;
    border-radius: 4px;
}
.col-left {
    display: flex;
    align-content: center;
    text-align: left;
    vertical-align: middle;
    max-width: fit-content;
}
.col-right {
    text-align: right;
    max-width: fit-content;
}
.col-right-light {
    text-align: right;
    font-size: 10px;
    color: #d3d3d3;
    background-color: #fcfcfc;
    margin: 0px !important;
}
.col-center {
    max-width: fit-content;
    text-align: center;
}

.page-item a{
    color: black;
}

.page-item a:hover{
    color: #4d4d4d;
}

.card {
    border: none;
    justify-content: left;
    text-align: left;
    padding-left: 8%;
    padding-right: 8%;
}

.addNewCard {
    border: none;
    justify-content: center;
    text-align: center;
    padding-top: 25px;
}

.cardIcon {
    width: 100%;
    text-align: right;
    font-size: smaller;
    color: grey;
    height: 35px;
}
.cardID {
    width: 100%;
    text-align: right;
    font-size: smaller;
    color: rgb(207, 207, 207);
    height: 10px;
}

.edit-button{
    border: none;
    background-color: white;
    color: #4d4d4d;
}

a {
    color: #4d4d4d;
}

a:hover {
    color: black;
}

.edit-input {
    padding: 6px;
    margin: 2px;
    border: none;
    border-bottom: 1px solid gray;
    background-color: #fcfcfc;
    color: black;
    width: 100%;
} 
input:focus {
    border: none !important;
    background-color: white;
}

textarea {
    height: 150px;
}

.card-loading{
    text-align: center;
    height: auto;
}
.actions {
    display: flex;
}

.go-back {
    width: 75%;
    margin-left: 3%;
}
.goBack {
    width: 75%;
    margin-left: 2%;
    color: #fcfcfc;
    cursor: pointer;
}
.goBack:hover {
    color:#acf06c
}

.updateMessage{
    width: 75%;
    text-align: center;
}

.cardEdit {
    width: 25%;
    text-align: right;
    font-size: smaller;
    color: grey;
    margin-right: 5%;
}
.cardViewEdit {
    width: 25%;
    text-align: right;
    font-size: smaller;
    color: #fcfcfc;
    margin-right: 2%;
}
.cardViewEdit a:link {
    color: #fcfcfc;
}
.cardViewEdit a:visited {
    color: #fcfcfc;
}
.cardViewEdit a:hover {
    color:#acf06c;
}
.cardViewEditBlack {
    width: 25%;
    text-align: right;
    font-size: smaller;
    color: #000;
    margin-right: 2%;
}
.cardViewEditBlack a:link {
    color: #000;
}
.cardViewEditBlack a:visited {
    color: #000;
}

.card-image {
    width: 100% !important;
    text-align: center;
    padding: 24px;
}

.custom-col-card-image {
    text-align: center;
    max-width: 50%;
}

.custom-card-image {
    height: 450px;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

.card-title {
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 0px;
    padding-left: 9%;
}

.card-set {
    font-size: 16px;
    padding-left: 10%;
}

.custom-info-title {
    text-align: right;
    font-weight: bold;
    font-size: 11px;
    vertical-align: middle !important;
    padding: 3px;
}
.custom-input-title {
    width: 100px;
    text-align: right;
    font-weight: bold;
    font-size: 13px;
    vertical-align: middle !important;
    padding: 3px;
}

.custom-info-val {
    text-align: center;
    font-size: 13px;
    display: 'flex';
    padding: 3px;
}
.cardProfitValue {
    text-align: center;
    font-weight:bolder;
    font-size: 13px;
    display: 'flex';
    padding: 3px;
}
.custom-info-notes {
    height: 75ps;
    font-size: 15px;
    display: 'flex';
    padding: 3px;
}
.custom-info-notes-title {
    text-align: center;
    font-weight: bold;
    font-size: 11px;
    vertical-align: middle !important;
    padding: 3px;
}
.custom-info-misc {
    font-size: 13px;
    display: 'flex';
    padding: 3px;
}

.custom-info-header {
    text-align: center;
    color: #4d4d4d;
    font-weight: bold;
    font-size: smaller;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    background-color: white;
    border-radius: 15px;
    padding: 2%;
}

.custom-row-centering {
   text-align: center; 
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: black !important;
    background-color: #acf06c !important;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.custom-nav-link .custom-nav-link.active a {
    color: black !important;
}

.nav-link {
    color: #4d4d4d;
}

.nav {
    --bs-nav-link-hover-color: black;
}

.custom-info-box {
    background-color: #fcfcfc;
    border-radius: 10px;
    margin: 10px;
}
.custom-note-box {
    background-color: #fcfcfc;
    border-radius: 10px;
    margin: 10px;
    max-width: 97.25%;
}

.custom-card-box {
    background-color: #fcfcfc;
    border-radius: 10px;
    margin: 10px;
    width: 275px;
}

.card-images {
    display: flex;
    text-align: center;
    padding-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
}
.edit-card-images {
    display: flex;
    text-align: center;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
}
.editCardImageDisplay {
    height: 150px;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
}
.card-row-center {
    width: 100% !important;
    text-align: center;
}

.uploadProgressRow {
    padding: 15px;
    height: 50px;
    max-width: 50%;
}

.uploadButton {
    width: 50%;
    border-radius: 10px;
    font-weight: bold;
    border-width: 0px;
    box-shadow: 0px 2px 4px #bdbdbd;
    color: #222;
}
.uploadButton:hover {
    background-color: #222;
    color: white;
}

.imagesPage{
    background-color: #FFF;
}
.imagesPageRow{
    padding-right: 2px !important;
    padding-left: 2px !important;
    padding: 3px !important;
}
.cardWallGrader {
    text-transform: uppercase;
    width: 100%;
    text-align: center;
}
.spriteImages {
    padding: 10px;
}

.cardTopBG {
    background-color: #222;
    height: 320px;
    display: flex;
    align-items: left;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: 0px 0px 15px rgb(206, 206, 206);
}

.cardImageBox {
    display: flex;
    text-align: center;
    padding-bottom: 20px;
    position: relative;
    top: -240px;
    height: 280px;
}
.cardSearch {
    height: 36px;
    width: 50%;
}
